import mapValues from 'lodash/mapValues';
import round from 'lodash/round';
import { carRental, external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { portalHost } from '../../../const/host.const';

const { getVehicleTrips, getVehicleTripsExport } = carRental.vehicle;
const { getReverseGeocode } = external.app;

export const scopes = {
  summary: 'summary',
  exportTrips: 'exportTrips',
  exportSingleTrip: 'exportSingleTrip',
  address: 'address',
};

const tripDetails = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      actions: {
        getVehicleTrips({ rootState, commit }, { vehicleVin, data }) {
          return runAsyncFlow(commit, {
            request: getVehicleTrips,
            params: [vehicleVin, data, portalHost(rootState)],
            scope: scopes.summary,
          });
        },
        getVehicleTripsExport({ rootState, commit }, { vehicleVin, data }) {
          runAsyncFlow(commit, {
            request: getVehicleTripsExport,
            params: [vehicleVin, data, portalHost(rootState)],
            scope: scopes.exportTrips,
          });
        },
        getVehicleSingleTripExport({ rootState, commit }, { vehicleVin, data }) {
          runAsyncFlow(commit, {
            request: getVehicleTripsExport,
            params: [vehicleVin, data, portalHost(rootState)],
            scope: scopes.exportSingleTrip,
          });
        },
        async getReverseGeocode({ commit, state }, { tripId, query }) {
          await runAsyncFlow(commit, {
            request: getReverseGeocode,
            params: [query],
          }).then(address => {
            const { addressCity, addressCountry, addressStreet } = address || {};
            const hasGeolocationFailed = !addressCity && !addressCountry && !addressStreet;

            const { latitude, longitude } = query;
            const truncatedGps = { lat: latitude, lng: longitude };
            const coordinates = mapValues(truncatedGps, value => round(value, 4));

            const addressString = hasGeolocationFailed
              ? `Unknown address ${JSON.stringify(coordinates)}`
              : ` ${addressCountry ? `${addressCountry},` : 'Unknown country'}
              ${addressCity ? `${addressCity},` : 'Unknown city'}
              ${addressStreet}`;

            const payload = { [tripId]: addressString };

            state[scopes.address].data = { ...state[scopes.address].data, ...payload };
          });
        },
      },
    }
  )));

export default tripDetails;
